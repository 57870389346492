/* global angular, google */

// OpenGarage
// Controllers are found in controllers.js and utilities are located in utils.js
angular.module( "opengarage", [ "ionic", "uiCropper", "opengarage.controllers", "opengarage.utils", "opengarage.cloud", "opengarage.watch" ] )
	.run( function( $state, $ionicPlatform, $ionicScrollDelegate, $document, $window, $rootScope, $filter, $ionicLoading, $timeout, Utils, Cloud, Watch ) {

		// Ready function fires when the DOM is ready and after deviceready event is fired if Cordova is being used
		$ionicPlatform.ready( function() {

			// Map inAppBrowser to rootScope
			$rootScope.open = function( url ) {
				if ( window.SafariViewController ) {
					window.SafariViewController.isAvailable( function( available ) {
						if ( available ) {
							window.SafariViewController.show( {
								url: url,
								tintColor: "#444444",
								barColor: "#444444",
								controlTintColor: "#ffffff"
							} );
						} else {
							window.open( url, "_blank", "toolbarposition=top" );
						}
					} );
				} else {
					window.open( url, "_blank", "toolbarposition=top" );
				}
			};

			// Hide the accessory bar by default (remove this to show the accessory bar above the keyboard
			// for form inputs)
			if ( window.cordova && window.cordova.plugins && window.cordova.plugins.Keyboard && window.cordova.plugins.Keyboard.hideKeyboardAccessoryBar ) {
				window.cordova.plugins.Keyboard.hideKeyboardAccessoryBar( true );
			}
			if ( window.StatusBar ) {

				// Use white text/icons on the status bar
		        window.StatusBar.styleLightContent();

		        //Change the status bar color to match the header
                window.StatusBar.backgroundColorByHexString( "#444" );

		        // Scroll to the top of the page when the status bar is tapped
				angular.element( $window ).on( "statusTap", function() {
					$ionicScrollDelegate.scrollTop();
		        } );
			}

			if ( window.geofence ) {
				$rootScope.hasGeofence = true;

				$rootScope.startGeofence = function( callback ) {
					window.geofence.initialize().then( function() {
						callback( true );
					}, function() {
						callback( false );
					} );
				};

				window.geofence.getWatched( function( fences ) {
					$rootScope.geoFences = JSON.parse( fences );
				} );

				var handleGeofence = function( geofences ) {
					if ( geofences ) {

						// Allow handler to process notification click event as well
						if ( geofences.notification ) {
							geofences = [ geofences ];
						}

						$rootScope.$apply( function() {
							geofences.forEach( function( geo ) {
								var data = geo.notification.id.split( "-" ),
									controller = $filter( "filter" )( $rootScope.controllers, { "mac": data[ 1 ] } );

								if ( controller &&
									( data[ 0 ] === "open" && controller.door === 0 ) &&
									( data[ 0 ] === "close" && controller.door === 1 ) ) {
										Utils.toggleDoor( controller.auth, null, controller.bdmn + ":" + controller.bprt );
								}
							} );
						} );
					}
				};

				window.geofence.onTransitionReceived = handleGeofence;
				window.geofence.onNotificationClicked = handleGeofence;
			}

			if ( window.ThreeDeeTouch ) {
				Utils.updateQuickLinks();

				window.ThreeDeeTouch.enableLinkPreview();

				window.ThreeDeeTouch.onHomeIconPressed = function( payload ) {

					var data = payload.type.split( "-" );

					if ( data[ 0 ] === "toggle" ) {
						var controller = $filter( "filter" )( $rootScope.controllers, { "mac": data[ 1 ] } );
						if ( controller ) {
							Utils.toggleDoor( controller.auth, null, controller.bdmn + ":" + controller.bprt );
						}
					}
				};
			}

			if ( window.applewatch && window.navigator.simulator !== true ) {
				window.applewatch.init( angular.noop );

				window.toggleDoorByIndex = function( index ) {
					Utils.toggleDoor( $rootScope.controllers[ index ].auth, null, $rootScope.controllers[ index ].bdmn + ":" + $rootScope.controllers[ index ].bprt );
				};

				window.applewatch.callback.onLoadAppMainRequest = Watch.loadApp;
			}

		    // Hide the splash screen after 500ms of the app being ready
		    $timeout( function() {
		        try {
		            navigator.splashscreen.hide();
		        } catch ( err ) {}
		    }, 500 );
		} );

		// Define connected state of current controller
		$rootScope.connected = false;

		// Define version, build number and debug state
		$rootScope.version = window.appVersion;

	    // Define total loading requests
	    $rootScope.loadingQueue = [];

	    // Initialize controllers array
	    $rootScope.controllers = [];

		// Automatically show a loading message on any AJAX request
		$rootScope.$on( "loading:show", function( e, data ) {
			var scope = $rootScope.$new();

			scope.canceller = data ? data.canceller.resolve : function() {
				$rootScope.$broadcast( "loading:hide" );
			};

			$rootScope.loadingQueue.push( scope.canceller );
			$ionicLoading.show( {
				template: "<ion-spinner></ion-spinner><br>One moment please<br><button class='button white icon-left ion-ios-close-outline button-clear' ng-click='canceller()'>Cancel</button>",
				scope: scope
			} );
		} );

		// Automatically hide the loading message after an AJAX request
		$rootScope.$on( "loading:hide", function() {

			$rootScope.loadingQueue.pop();
			$ionicLoading.hide();
			if ( $rootScope.loadingQueue.length > 0 ) {
				var scope = $rootScope.$new();
				scope.canceller = $rootScope.loadingQueue[ $rootScope.loadingQueue.length - 1 ];
				$ionicLoading.show( {
					template: "<ion-spinner></ion-spinner><br>One moment please<br><button class='button white icon-left ion-ios-close-outline button-clear' ng-click='canceller()'>Cancel</button>",
					scope: scope
				} );
			}
		} );

		// Keep the page title locked as the app name
		$rootScope.$on( "$ionicView.afterEnter", function() {
			$document[ 0 ].title = "OpenGarage";
		} );

		angular.element( $document ).on( "resume", function() {
			$timeout( function() {
				Utils.checkNewController();
				Cloud.sync();
			}, 100 );
		} );

		// Handle loading of the first page
		var firstLoadHandler = $rootScope.$on( "$stateChangeStart", function( event ) {

			// Unbind event handler so this check is only performed on the first app load
		    firstLoadHandler();

		    // Prevent any page change from occurring
	        event.preventDefault();

		    Utils.storage.get( [ "activeController", "controllers", "cloudToken" ], function( data ) {

				try {
					$rootScope.controllers = JSON.parse( data.controllers );
					$rootScope.activeController = JSON.parse( data.activeController );
				} catch ( err ) {}

				if ( !$rootScope.controllers ) {
					$rootScope.controllers = [];
				}

				if ( !$rootScope.activeController || typeof $rootScope.activeController !== "object" ) {
					$rootScope.activeController = {};
				}

				Cloud.sync();

				// Restore the active controller, if available
		        if ( Object.keys( $rootScope.activeController ).length ) {

					// If a user object is cached, proceed to load the app while updating user object in the background
					$state.go( "app.home" );

					Utils.updateController();
		        } else {
					if ( $rootScope.controllers.length || data.cloudToken ) {
						$state.go( "app.controllerSelect" );
					} else {
						$state.go( "login" );
					}
		        }
		    } );

		    Utils.checkNewController();
		} );

		// Resize the main content view to fit when the window changes size
		angular.element( window ).on( "resize", function() {
			$rootScope.$broadcast( "window:resize" );
			var content = document.getElementById( "mainContent" );
			if ( content && window.innerWidth > 768 ) {
				content.width = window.innerWidth - 275;
			}
		} );
	} )

	.config( function( $stateProvider, $httpProvider, $compileProvider, $ionicConfigProvider ) {

		if ( /MSIE\s|Trident\/|Edge\//.test( window.navigator.userAgent ) ) {

			// Change the default spinner for IE to android which has JS based animation
			$ionicConfigProvider.platform.default.spinner.icon( "android" );
		}

		// Change the back text to always say 'Back'
		$ionicConfigProvider.backButton.previousTitleText( false ).text( "Back" );

		// Use native scrolling for FireFox
		if ( /Firefox/.test( navigator.userAgent ) ) {
			$ionicConfigProvider.scrolling.jsScrolling( false );
		}

		// Modify Ionic's allowed href protocols to allow Firefox, Blackberry, iOS and Chrome support
		$compileProvider.aHrefSanitizationWhitelist( /^\s*(https?|ftp|mailto|chrome-extension|app|local|file):/ );

		// Modify Ionic's allowed image source protocols
		$compileProvider.imgSrcSanitizationWhitelist( /^\s*(https?|ftp|file|content|blob|ms-appx|x-wmapp0|chrome-extension|app|local):|data:image\// );

		// Define all available routes and their associated controllers
		$stateProvider
			.state( "login", {
				url: "/login",
				templateUrl: "templates/login.html",
				controller: "LoginCtrl"
			} )

			// Used as the parent route after authentication is complete allowing the same header
			// and side menu's to be used throughout all child views
			.state( "app", {
				url: "/app",
				abstract: true,
				templateUrl: "templates/menu.html",
				controller: "MenuCtrl"
			} )

			.state( "app.home", {
				url: "/home",
				views: {
					menuContent: {
						templateUrl: "templates/home.html",
						controller: "HomeCtrl"
					}
				},
				resolve: {
					checkValid: function( $timeout, $q, $state, $rootScope, $filter, Utils ) {
						var total = $rootScope.controllers.length,
							filterFilter = $filter( "filter" ),
							invalidOrg = ( $rootScope.activeController && filterFilter( $rootScope.controllers, { "mac": $rootScope.activeController.mac } ).length === 0 ) ? true : false;

						if ( !$rootScope.activeController && total === 1 ) {
							Utils.setController( 0 );
							return;
						}

						if ( !$rootScope.activeController || invalidOrg ) {

							delete $rootScope.activeController;
							Utils.storage.remove( "activeController" );

							// If the active controller is not found in the controller list, show the select controller screen
							$timeout( function() {
								$state.go( "app.controllerSelect" );
							} );

							return $q.reject();
						}
					}
				}
			} )

			.state( "app.rules", {
				url: "/rules",
				views: {
					menuContent: {
						templateUrl: "templates/rules.html",
						controller: "RulesCtrl"
					}
				}
			} )

			.state( "app.controllerSelect", {
				url: "/controllerSelect",
				views: {
					menuContent: {
						templateUrl: "templates/controllerSelect.html",
						controller: "ControllerSelectCtrl"
					}
				}
			} )

			.state( "app.history", {
				url: "/history",
				views: {
					menuContent: {
						templateUrl: "templates/history.html",
						controller: "HistoryCtrl"
					}
				},
				resolve: {
					checkValid: function( $rootScope, $q ) {
						if ( !$rootScope.activeController ) {
							return $q.reject();
						}
					}
				}
			} )

			.state( "app.settings", {
				url: "/settings",
				views: {
					menuContent: {
						templateUrl: "templates/settings.html",
						controller: "SettingsCtrl"
					}
				},
				resolve: {
					checkValid: function( $rootScope, $q ) {
						if ( !$rootScope.activeController ) {
							return $q.reject();
						}
					}
				}
			} )

			.state( "app.help", {
				url: "/help",
				views: {
					menuContent: {
						templateUrl: "templates/help.html"
					}
				}
			} )

			// Defines the otherwise route which will redirect any invalid URL back to the start page
			.state( "otherwise", {
				url: "*path",
				template: "",
				controller: function( $state, $rootScope ) {

					if ( $rootScope.activeController ) {
						$state.go( "app.home" );
					} else {
						$state.go( "app.controllerSelect" );
					}
				}
			} );

		// Add an HTTP interceptor
		$httpProvider.interceptors.push( function( $rootScope, $q, $injector ) {
			return {
				request: function( config ) {

					// When an AJAX request to the controller is started, fire an event to show a loading message
					if ( config.url.match( /^https?/ ) ) {

						// Change timeout to a promise we can cancel
						config.cancel = $q.defer();

						// Set the timeout to the canceller promise
						config.timeout = config.cancel.promise;

						// Set the current retry count to 0
						if ( typeof config.retryCount !== "number" ) {
							config.retryCount = 0;
						}

						if ( !config.suppressLoader ) {
							$rootScope.$broadcast( "loading:show", { canceller: config.cancel } );
						}
					}

					return config;
				},
				response: function( response ) {

					// If the request is to the controller, broadcast a hide loading message
					if ( !response.config.suppressLoader && response.config.url.match( /^https?/ ) ) {
						$rootScope.$broadcast( "loading:hide" );
					}

					return response;
				},
				responseError: function( error ) {

					// If the timeout value is an object and is resolved, mark request as user canceled
					if ( error.config.timeout && error.config.timeout.$$state && error.config.timeout.$$state.status === 1 ) {
						error.canceled = true;
					}

					if ( !error.config.suppressLoader && error.config.url.match( /^https?/ ) ) {
						$rootScope.$broadcast( "loading:hide" );
					}

					// If the request timed out and is not user canceled, retry the request up to three times
					if ( error.status <= 0 && !error.canceled ) {
						if ( error.config.retryCount < 3 ) {
							var $http = $injector.get( "$http" );

							error.config.retryCount++;

							// Return the new promise object
							return $http( error.config );
						} else {

							// After three timeouts, assume the network is down
							error.retryFailed = true;
							error.canceled = true;

							return $q.reject( error );
						}
					}

					return $q.reject( error );
				}
			};
		} );

		// Inform Ionic we want to cache forward views
		$ionicConfigProvider.views.forwardCache( true );
	} )

	.filter( "unique", function() {
		return function( items, filterOn ) {
			if ( filterOn === false ) {
				return items;
			}

			if ( ( filterOn || angular.isUndefined( filterOn ) ) && angular.isArray( items ) ) {
				var newItems = [],
					extractValueToCompare = function( item ) {
						if ( angular.isObject( item ) && angular.isString( filterOn ) ) {
							return item[ filterOn ];
						} else {
							return item;
						}
					};

				angular.forEach( items, function( item ) {
					var isDuplicate = false;

					for ( var i = 0; i < newItems.length; i++ ) {
						if ( angular.equals( extractValueToCompare( newItems[ i ] ), extractValueToCompare( item ) ) ) {
							isDuplicate = true;
							break;
						}
					}
					if ( !isDuplicate ) {
						newItems.push( item );
					}
				} );
				items = newItems;
			}
		return items;
		};
	} )

	.directive( "geoRuleSetup", function( $rootScope, $filter ) {
		return {
			restrict: "E",
			replace: true,
			scope: {
				rule: "="
			},
			templateUrl: "templates/geoRuleSetup.html",
			link: function( scope, element ) {
				var map, marker, circle;

				scope.updateRule = function() {
					$rootScope.startGeofence( function() {
						window.geofence.addOrUpdate( {
							id: scope.rule.direction + "-" + $rootScope.activeController.mac,
							latitude: scope.rule.start.lat,
							longitude: scope.rule.start.lng,
							radius: scope.rule.radius,
							transitionType: scope.rule.direction === "open" ? 1 : 2,
							notification: {
								title: "OpenGarage Reminder",
								text: "Tap here to " + scope.rule.direction + " the garage door...",
								openAppOnClick: true
							}
						}, function() {
							window.geofence.getWatched( function( fences ) {
								$rootScope.geoFences = JSON.parse( fences );
							} );
						} );
					} );
				};

				scope.updateMarker = function() {
					if ( marker ) {
						marker.setMap( null );
					}

					marker = new google.maps.Marker( {
						position: scope.rule.start,
						map: map
					} );

					scope.updateRule();
				};

				scope.updateRadius = function() {
					if ( circle ) {
						circle.setMap( null );
					}

					circle = new google.maps.Circle( {
						strokeColor: "#FF0000",
						strokeOpacity: 0.8,
						strokeWeight: 2,
						fillColor: "#FF0000",
						fillOpacity: 0.35,
						map: map,
						center: scope.rule.start,
						radius: parseInt( scope.rule.radius )
					} );

					scope.updateRule();
				};

				scope.updateMap = function() {
					if ( !scope.rule.enable ) {
						window.geofence.remove( scope.rule.direction + "-" + $rootScope.activeController.mac, function() {
							window.geofence.getWatched( function( fences ) {
								$rootScope.geoFences = JSON.parse( fences );
							} );
						} );
						return;
					}

					setTimeout( function() {
						map = new google.maps.Map( element[ 0 ].querySelectorAll( ".map" )[ 0 ], {
							zoom: 16,
							streetViewControl: false,
							mapTypeControl: false,
							center: scope.rule.start
						} );

						google.maps.event.addListener( map, "click", function( e ) {
							scope.rule.start = { lat: e.latLng.lat(), lng: e.latLng.lng() };
							scope.updateMarker();
							scope.updateRadius();
						} );

						scope.updateMarker();
						scope.updateRadius();
					}, 100 );
				};

				scope.$watch( "rule", function() {
					var currentRule = $filter( "filter" )( $rootScope.geoFences, { "id": scope.rule.direction + "-" + $rootScope.activeController.mac } )[ 0 ];

					if ( currentRule ) {
						scope.rule.radius = currentRule.radius;
						scope.rule.start = { lat: currentRule.latitude, lng: currentRule.longitude };
						scope.rule.enable = true;
						scope.updateMap();
					} else {
						scope.rule.radius = scope.rule.radius || 500;
						scope.rule.start = scope.rule.start || { lat: 30.296519, lng: -97.730185 };
						scope.rule.enable = scope.rule.enable || false;

						$rootScope.startGeofence( function() {
							navigator.geolocation.getCurrentPosition( function( position ) {
								scope.rule.start = { lat: position.coords.latitude, lng: position.coords.longitude };
								scope.updateMap();
							}, function() {
								scope.updateMap();
							}, { timeout: 10000 } );
						} );
					}
				} );
			}
		};
	} );

/* global angular, sjcl */

// OpenGarage
angular.module( "opengarage.cloud", [ "opengarage.utils" ] )
    .factory( "Cloud", [ "$injector", "$rootScope", "Utils", function( $injector, $rootScope, Utils ) {

        var requestAuth = function() {
                $ionicPopup = $ionicPopup || $injector.get( "$ionicPopup" );

                var scope = $rootScope.$new();
                scope.data = {};

                $ionicPopup.show( {
                    templateUrl: "templates/cloudLogin.html",
                    title: "OpenGarage.io Login",
                    scope: scope,
                    buttons: [
                        { text: "Cancel" },
                        {
                            text: "<b>Login</b>",
                            type: "button-positive",
                            onTap: function( e ) {
                                if ( !scope.data.username || !scope.data.password ) {
                                    e.preventDefault();
                                    return;
                                }

                                return true;
                            }
                        }
                    ]
                } ).then( function( isValid ) {
                    if ( isValid ) {
                        login( scope.data.username, scope.data.password, syncStart );
                    }
                } );
            },
            login = function( user, pass, callback ) {
                callback = callback || function() {};
                $http = $http || $injector.get( "$http" );
                $httpParamSerializerJQLike = $httpParamSerializerJQLike || $injector.get( "$httpParamSerializerJQLike" );

                $http( {
                    method: "POST",
                    url: "https://opengarage.io/wp-admin/admin-ajax.php",
					headers: {
						"Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
					},
                    data: $httpParamSerializerJQLike( {
                        action: "ajaxLogin",
                        username: user,
                        password: pass
                    } )
                } ).then( function( result ) {
                    if ( typeof result.data.token === "string" ) {
                        Utils.storage.set( {
                            "cloudToken": result.data.token,
                            "cloudDataToken": sjcl.codec.hex.fromBits( sjcl.hash.sha256.hash( pass ) )
                        } );
                        $rootScope.isSynced = true;
                    }
                    callback( result.data.loggedin );
                }, function() {
                    callback( false );
                } );
            },
            logout = function() {
                Utils.storage.remove( "cloudToken" );
                $rootScope.isSynced = false;
            },
            syncStart = function() {
                $ionicActionSheet = $ionicActionSheet || $injector.get( "$ionicActionSheet" );

                getSites( function( sites ) {
                    if ( JSON.stringify( sites ) === JSON.stringify( $rootScope.controllers ) ) {
                        return;
                    }

                    if ( Object.keys( sites ).length > 0 ) {

                        var finish = function() {
							$rootScope.controllers = sites;
                            Utils.storage.set( { "controllers": JSON.stringify( sites ) }, saveSites );
                            Utils.updateQuickLinks();
                        };

                        // Handle how to merge when cloud is populated
                        $ionicActionSheet.show( {
                            buttons: [
                                { text: "<i class='icon ion-merge'></i> Merge" },
                                { text: "<i class='icon ion-ios-cloud-download'></i> Replace local with cloud" },
                                { text: "<i class='icon ion-ios-cloud-upload'></i> Replace cloud with local" }
                            ],
                            titleText: "Select Merge Method",
                            cancelText: "Cancel",
                            buttonClicked: function( index ) {
                                if ( index === 1 ) {

                                    // Replace local with cloud
                                    finish();
                                } else if ( index === 2 ) {

                                    // Replace cloud with local
                                    sites = $rootScope.controllers;
                                    finish();
                                } else {
									$filter = $filter || $injector.get( "$filter" );

                                    // Merge data
                                    sites = $filter( "unique" )( $rootScope.controllers.concat( sites ), "mac" );
                                    finish();
                                }
                                return true;
                            }
                        } );
                    } else {
                        saveSites();
                    }
                } );
            },
            sync = function( callback ) {
                callback = callback || function() {};

                Utils.storage.get( "cloudToken", function( local ) {
                    if ( typeof local.cloudToken !== "string" ) {
                        return;
                    }

                    getSites( function( data ) {
                        if ( data !== false ) {
                            $rootScope.controllers = data;
                            Utils.storage.set( { "controllers": JSON.stringify( data ) }, callback );
                            Utils.updateQuickLinks();
                        }
                    } );
                } );
            },
            getSites = function( callback ) {
                callback = callback || function() {};
                $http = $http || $injector.get( "$http" );
                $httpParamSerializerJQLike = $httpParamSerializerJQLike || $injector.get( "$httpParamSerializerJQLike" );

                Utils.storage.get( [ "cloudToken", "cloudDataToken" ], function( local ) {
                    if ( local.cloudToken === undefined || local.cloudToken === null ) {
                        callback( false );
                        return;
                    }

                    if ( local.cloudDataToken === undefined || local.cloudDataToken === null ) {
                        handleInvalidDataToken( function( success ) {
                            if ( success ) {
                                getSites( callback );
                            } else {
                                callback( false );
                            }
                        } );
                        return;
                    }

                    $http( {
                        method: "POST",
                        url: "https://opengarage.io/wp-admin/admin-ajax.php",
						headers: {
							"Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
						},
                        data: $httpParamSerializerJQLike( {
                            action: "getSites",
                            token: local.cloudToken,
                            controllerType: "opengarage"
                        } )
                    } ).then( function( result ) {
                        if ( result.data.success === false || result.data.sites === "" ) {
                            if ( result.data.message === "BAD_TOKEN" ) {
                                handleExpiredLogin();
                            }
                            callback( false, result.data.message );
                        } else {
                            Utils.storage.set( { "cloudToken": result.data.token } );
                            var sites;

                            try {
                                sites = sjcl.decrypt( local.cloudDataToken, result.data.sites );
                            } catch ( err ) {
                                if ( err.message === "ccm: tag doesn't match" ) {
                                    handleInvalidDataToken( function( success ) {
                                        if ( success ) {
                                            getSites( callback );
                                        } else {
                                            callback( false );
                                        }
                                    } );
                                }
                            }

                            try {
                                callback( JSON.parse( sites ) );
                            } catch ( err ) {
                                callback( false );
                            }
                        }
                    }, function() {
                        callback( false );
                    } );
                } );
            },
            saveSites = function( callback ) {
				if ( typeof callback !== "function" ) {
	                callback = function() {};
				}
                $http = $http || $injector.get( "$http" );
                $httpParamSerializerJQLike = $httpParamSerializerJQLike || $injector.get( "$httpParamSerializerJQLike" );

                Utils.storage.get( [ "cloudToken", "cloudDataToken" ], function( data ) {
                    if ( data.cloudToken === null || data.cloudToken === undefined ) {
                        callback( false );
                        return;
                    }

                    $http( {
                        method: "POST",
                        url: "https://opengarage.io/wp-admin/admin-ajax.php",
						headers: {
							"Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
						},
                        suppressLoader: true,
                        data: $httpParamSerializerJQLike( {
                            action: "saveSites",
                            token: data.cloudToken,
                            controllerType: "opengarage",
                            sites: encodeURIComponent( JSON.stringify( sjcl.encrypt( data.cloudDataToken, JSON.stringify( $rootScope.controllers ) ) ) )
                        } )
                    } ).then( function( result ) {
                        if ( result.data.success === false ) {
                            if ( result.data.message === "BAD_TOKEN" ) {
                                handleExpiredLogin();
                            }
                            callback( false, result.data.message );
                        } else {
                            Utils.storage.set( { "cloudToken": result.data.token } );
                            callback( result.data.success );
                        }
                    }, function() {
                        callback( false );
                    } );
                } );
            },
            handleInvalidDataToken = function( callback ) {
				if ( typeof callback !== "function" ) {
	                callback = function() {};
				}
                Utils.storage.remove( "cloudDataToken" );

                $ionicPopup = $ionicPopup || $injector.get( "$ionicPopup" );
                $ionicPopup.prompt( {
                    title: "Unable to read cloud data",
                    subTitle: "Enter a valid password to decrypt the data",
                    template: "Please enter your OpenSprinkler.com password. If you have recently changed your password, you may need to enter your previous password to decrypt the data.",
                    inputType: "password",
                    inputPlaceholder: "Password"
                } ).then(
                    function( password ) {
                        Utils.storage.set( {
                            "cloudDataToken": sjcl.codec.hex.fromBits( sjcl.hash.sha256.hash( password ) )
                        } );
                        callback( true );
                    },
                    function() {
                        callback( false );
                    }
                );
            },
            handleExpiredLogin = function() {
                Utils.storage.remove( "cloudToken" );

                $ionicPopup = $ionicPopup || $injector.get( "$ionicPopup" );
                $ionicPopup.confirm( {
                    title: "OpenGarage.io Login Expired",
                    template: "Click here to re-login to OpenGarage.io"
                } ).then( function( result ) {
                    if ( result ) {

                        requestAuth( function( result ) {
                            if ( result === true ) {
                                sync();
                            }
                        } );

                    }
                } );
            },
            getTokenUser = function( token ) {
                return atob( token ).split( "|" )[ 0 ];
            },
            $http, $httpParamSerializerJQLike, $filter, $ionicPopup, $ionicActionSheet;

        Utils.storage.get( [ "cloudToken", "cloudDataToken" ], function( data ) {
			if ( data.cloudToken === null || data.cloudToken === undefined || data.cloudDataToken === undefined || data.cloudDataToken === undefined ) {
				$rootScope.isSynced = false;
			} else {
				$rootScope.isSynced = true;
			}
        } );

        $rootScope.$on( "controllersUpdated", saveSites );

        return {
            requestAuth: requestAuth,
            login: login,
            logout: logout,
            syncStart: syncStart,
            sync: sync,
            getSites: getSites,
            saveSites: saveSites,
            handleInvalidDataToken: handleInvalidDataToken,
            handleExpiredLogin: handleExpiredLogin,
            getTokenUser: getTokenUser
        };
    } ] );

/* global angular, ionic */

angular.module( "opengarage.controllers", [ "opengarage.utils", "opengarage.cloud" ] )

	.controller( "LoginCtrl", function( $scope, $state, $ionicPopup, Cloud ) {
		$scope.data = {};

		$scope.submit = function() {
			if ( !$scope.data.username ) {

				// If no email is provided, throw an error
				$ionicPopup.alert( {
					template: "<p class='center'>Please enter a username to continue.</p>"
				} );
			} else if ( !$scope.data.password ) {

				// If no password is provided, throw an error
				$ionicPopup.alert( {
					template: "<p class='center'>Please enter a password to continue.</p>"
				} );
			} else {
				Cloud.login( $scope.data.username, $scope.data.password, function() {
					Cloud.sync( function() {
						$state.go( "app.controllerSelect" );
					} );
				} );
			}
		};

		$scope.skipCloud = function() {
			$state.go( "app.controllerSelect" );
		};
	} )

	.controller( "ControllerSelectCtrl", function( $scope, $state, $rootScope, $ionicHistory, Utils, Cloud ) {
		$scope.data = {
			showDelete: false
		};

		$scope.selectPhoto = Utils.selectPhoto;

		$scope.setController = function( index ) {
			Utils.setController( index );

			$ionicHistory.nextViewOptions( {
				historyRoot: true
			} );

			$state.go( "app.home" );
		};

		$scope.deleteController = function( index ) {
			if ( Utils.getControllerIndex() === index ) {
				delete $rootScope.activeController;
				Utils.storage.remove( "activeController" );
			}

			$rootScope.controllers.splice( index, 1 );
			Utils.storage.set( { controllers: JSON.stringify( $rootScope.controllers ) } );
			$rootScope.$broadcast( "controllersUpdated" );
		};

		$scope.moveItem = function( item, fromIndex, toIndex ) {
			$rootScope.controllers.splice( fromIndex, 1 );
			$rootScope.controllers.splice( toIndex, 0, item );
			Utils.storage.set( { controllers: JSON.stringify( $rootScope.controllers ) } );
			$rootScope.$broadcast( "controllersUpdated" );
		};

		$scope.getTime = function( timestamp ) {
			return new Date( timestamp ).toLocaleString();
		};

		$scope.changeSync = function() {
			if ( $rootScope.isSynced ) {
				Cloud.logout();
			} else {
				Cloud.requestAuth();
			}
		};
	} )

	.controller( "HistoryCtrl", function( $scope, Utils ) {
        $scope.isLocal = true;

		$scope.$on( "$ionicView.beforeEnter", function() {
			Utils.getLogs( function( reply ) {
				if ( reply ) {
					var i, current, day;

					for ( i = 0; i < reply.length; i++ ) {
						current = new Date( reply[ i ][ 0 ] * 1000 ).toDateString();

						if ( current !== day ) {
							day = current;
							reply.splice( i, 0, { isDivider: "true", day: current } );
						}
					}

					$scope.logs = reply;
				} else {
					$scope.isLocal = false;
				}
			} );
		} );
	} )

	.controller( "SettingsCtrl", function( $scope, $state, $ionicPopup, Utils ) {
		$scope.settings = {};
        $scope.isLocal = true;

		$scope.changePassword = Utils.changePassword;
		$scope.restart = Utils.restartController;

		$scope.submit = function() {
			Utils.saveOptions( $scope.settings, function( reply ) {
				var text;

				if ( reply ) {
					text = "Settings saved successfully!";
					$state.go( "app.home" );
				} else {
					text = "Unable to save settings. Please check the connection to the device and try again.";
				}
				$ionicPopup.alert( {
					template: "<p class='center'>" + text + "</p>"
				} );
			} );
		};

		$scope.$on( "$ionicView.beforeEnter", function() {
			Utils.getControllerOptions( function( reply ) {
				if ( reply ) {

					// Remove unused options to prevent accidental change
					delete reply.mod;
					delete reply.fwv;
					$scope.settings = reply;
				} else {
					$scope.isLocal = false;
				}
			}, null, null, true );
		} );
	} )

	.controller( "MenuCtrl", function( $scope, $rootScope, $ionicActionSheet, $ionicPopup, $ionicSideMenuDelegate, Utils ) {

		$scope.sideMenuDraggable = Utils.getControllerIndex() === 0 ? true : false;

		$rootScope.$on( "controllerUpdated", function() {
			$scope.sideMenuDraggable = Utils.getControllerIndex() === 0 ? true : false;
		} );

		$scope.showAddController = function() {
			$ionicActionSheet.show( {
				buttons: [
					{ text: "<i class='icon ion-plus-circled'></i> Add by IP" },
					{ text: "<i class='icon ion-network'></i> Add by Blynk Token" },
					{ text: "<i class='icon ion-network'></i> Add by OpenThings Cloud Token" },
					{ text: "<i class='icon ion-ios-color-wand'></i> Setup New Device" }
				],
				titleText: "Add Controller",
				cancelText: "Cancel",
				buttonClicked: function( index ) {
					if ( index === 0 ) {
						Utils.showAddController();
					} else if ( index === 1 ) {
						Utils.showAddBlynk();
                    } else if ( index === 2 ) {
                        Utils.showAddOtc();
					} else {
						Utils.checkNewController( function( result ) {
							if ( !result ) {
								$ionicPopup.alert( {
									template: "<p class='center'>Please first connect the power to your OpenGarage. Once complete, connect this device to the wifi network broadcast by the OpenGarage (named OG_XXXXXX) and reopen this app.</p>"
								} );
							}
						}, false );
					}
					return true;
				}
			} );
		};

		// Function to close the menu which is fired after a side menu link is clicked.
		// This is done instead of using the menu-close directive to preserve the root history stack
	    $scope.closeMenu = function() {
            $ionicSideMenuDelegate.toggleLeft( false );
	    };
	} )

	.controller( "HomeCtrl", function( $rootScope, $scope, $timeout, Utils ) {
        var interval;

		$scope.toggleDoor = Utils.toggleDoor;
		$scope.selectPhoto = Utils.selectPhoto;
		$scope.currentIndex = Utils.getControllerIndex();

		$scope.changeController = function( direction ) {
			var current = Utils.getControllerIndex(),
				to = current + direction;

			if ( current === -1 || to < 0 || to >= $rootScope.controllers.length ) {
				return;
			}

			$scope.currentIndex = to;
			Utils.setController( to );
		};

		$scope.$on( "$ionicView.beforeLeave", function() {
			clearInterval( interval );
		} );

		$scope.$on( "$ionicView.beforeEnter", function() {
            interval = setInterval( Utils.updateController, 5000 );
        } );

		$rootScope.$on( "controllerUpdated", function() {
			$scope.currentIndex = Utils.getControllerIndex();
			$timeout( function() {
				$scope.$apply();
			} );
		} );
	} )

	.controller( "RulesCtrl", function( $scope, $rootScope ) {
		var reset = function() {
			$scope.geo = {
				home: { direction: "open" },
				away: { direction: "close" }
			};
			$scope.set( "home" );
		};

		$scope.isAndroid = ionic.Platform.isAndroid();

		$scope.set = function( type ) {
			if ( type === "home" ) {
				$scope.current = $scope.geo.home;
			} else {
				$scope.current = $scope.geo.away;
			}
		};

		$rootScope.$on( "controllerUpdated", reset );
		reset();
	} );

/* global angular, window */

// TODO make this configurable.
var OPENTHINGS_CLOUD_HOST = "https://cloud.openthings.io";

// OpenGarage
angular.module( "opengarage.utils", [] )
    .factory( "Utils", [ "$injector", "$rootScope", function( $injector, $rootScope ) {

		var isFireFox = /Firefox/.test( window.navigator.userAgent ),
			isIE = /MSIE\s|Trident\/|Edge\//.test( window.navigator.userAgent ),

	        // Define storage wrapper functions
	        storage = {
	            get: function( query, callback ) {
	                callback = callback || function() {};

                    var data = {},
                        i;

                    if ( typeof query === "string" ) {
                        query = [ query ];
                    }

                    for ( i in query ) {
                        if ( query.hasOwnProperty( i ) ) {
                            data[ query[ i ] ] = localStorage.getItem( query[ i ] );
                        }
                    }

                    callback( data );
	            },
	            set: function( query, callback ) {
	                callback = callback || function() {};

                    var i;
                    if ( typeof query === "object" ) {
                        for ( i in query ) {
                            if ( query.hasOwnProperty( i ) ) {
                                localStorage.setItem( i, query[ i ] );
                            }
                        }
                    }

                    callback( true );
	            },
	            remove: function( query, callback ) {
	                callback = callback || function() {};

                    var i;

                    if ( typeof query === "string" ) {
                        query = [ query ];
                    }

                    for ( i in query ) {
                        if ( query.hasOwnProperty( i ) ) {
                            localStorage.removeItem( query[ i ] );
                        }
                    }

                    callback( true );
	            }
	        },
			intToIP = function( int ) {
				return ( int % 256 ) + "." + ( ( int / 256 >> 0 ) % 256 ) + "." + ( ( ( int / 256 >> 0 ) / 256 >> 0 ) % 256 ) + "." + ( ( ( int / 256 >> 0 ) / 256 >> 0 ) / 256 >> 0 );
			},
	        getControllerSettings = function( callback, ip, token, blynkServer ) {
				if ( !ip && !token && !$rootScope.activeController ) {
					callback( false );
					return;
				}

                $q = $q || $injector.get( "$q" );
				$http = $http || $injector.get( "$http" );

				var promise;

				if ( ( token && !isOTCToken( token ) ) || ( !ip && ( $rootScope.activeController && $rootScope.activeController.auth && !isOTCToken( $rootScope.activeController.auth ) ) ) ) {
                    if ( !blynkServer ) {
                        if ( !$rootScope.activeController.bdmn ) {
                            blynkServer = "blynk-cloud.com";
                        } else {
                            blynkServer = $rootScope.activeController.bdmn;
                        }

                        if ( $rootScope.activeController.bprt ) {
                            blynkServer += ":" + $rootScope.activeController.bprt;
                        }
                    }

                    promise = $q.all( {
                        name: $http( {
                            method: "POST",
                            url: "https://opengarage.io/wp-admin/admin-ajax.php",
                            headers: { "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8" },
                            data: "action=blynkCloud&server=" + encodeURIComponent( blynkServer ) + "&path=" + encodeURIComponent( token || $rootScope.activeController.auth ) + "/project",
                            suppressLoader: true
                        } ),
                        door: $http( {
                            method: "POST",
                            url: "https://opengarage.io/wp-admin/admin-ajax.php",
                            headers: { "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8" },
                            data: "action=blynkCloud&server=" + encodeURIComponent( blynkServer ) + "&path=" + encodeURIComponent( token || $rootScope.activeController.auth ) + "/get/V0",
                            suppressLoader: true
                        } ),
                        vehicle: $http( {
                            method: "POST",
                            url: "https://opengarage.io/wp-admin/admin-ajax.php",
                            headers: { "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8" },
                            data: "action=blynkCloud&server=" + encodeURIComponent( blynkServer ) + "&path=" + encodeURIComponent( token || $rootScope.activeController.auth ) + "/get/V4",
                            suppressLoader: true
                        } )
                    } );
				} else {
					promise = $http( {
						method: "GET",
						url: getBaseUrl( ip, token ) + "/jc",
						suppressLoader: ip ? false : true
					} );
				}

	            return promise.then(
					function( result ) {
						if ( ( token && !isOTCToken( token ) ) || ( !ip && ( $rootScope.activeController && $rootScope.activeController.auth && !isOTCToken( $rootScope.activeController.auth ) ) ) ) {
							if ( result.name.data === "Invalid token." ) {
								callback( false );
								return;
							}

							callback( {
								name: result.name.data.name,
								door: parseInt( result.door.data[ 0 ] ) === 255 ? 1 : 0,
                                lastUpdate: result.name.data.updatedAt,
                                vehicle: parseInt( result.vehicle.data[ 0 ] ) === 255 ? 1 : 0
							} );
						} else {
                            if ( result.status !== 200 ) {
                                callback( false );
                                return;
                            }

                            result.data.lastUpdate = new Date().getTime();
							callback( result.data );
						}
					},
					function() {
						callback( false );
					}
				);
	        },
	        getControllerOptions = function( callback, ip, token, showLoader ) {
				if ( ( !ip && !token && !$rootScope.activeController ) || ( $rootScope.activeController && !$rootScope.activeController.ip && !isOTCToken( $rootScope.activeController.auth ) ) ) {
					callback( false );
					return;
				}

				$http = $http || $injector.get( "$http" );

	            return $http( {
	                method: "GET",
	                url: getBaseUrl( ip, token ) + "/jo",
                    suppressLoader: showLoader ? false : true
	            } ).then(
					function( result ) {
						callback( result.data );
					},
					function() {
						callback( false );
					}
				);
	        },
	        updateController = function() {
				var controller = angular.copy( $rootScope.activeController ) || {};

				return getControllerSettings( function( data ) {
                    if ( data === false ) {
                        $rootScope.connected = false;
                        return;
                    }

                    $rootScope.connected = true;
                    angular.extend( controller, data );
                } ).then( function() {
                    var index = getControllerIndex();

                    if ( index >= 0 && controller.mac === $rootScope.activeController.mac ) {
                        $rootScope.controllers[ index ] = controller;
                        $rootScope.activeController = controller;
                        $rootScope.$broadcast( "controllerUpdated" );
                        $rootScope.$broadcast( "controllersUpdated" );
                        storage.set( { "controllers": JSON.stringify( $rootScope.controllers ), "activeController": JSON.stringify( $rootScope.activeController ) } );
                    }
                } );
	        },
	        getControllerIndex = function( mac ) {
				if ( !$rootScope.activeController && !mac ) {
					return null;
				}

				mac = mac || ( $rootScope.activeController === null ? "" : $rootScope.activeController.mac );

				for ( var i = 0; i < $rootScope.controllers.length; i++ ) {
					if ( $rootScope.controllers[ i ].mac === mac ) {
						return i;
					}
				}
				return null;
	        },
	        setController = function( controller, callback ) {
				callback = callback || function() {};

				cancelPendingHttp();
				$rootScope.activeController = typeof controller === "object" ? controller : $rootScope.controllers[ controller ];
				$rootScope.connected = false;
				updateController();
				storage.set( { activeController: JSON.stringify( $rootScope.activeController ) }, callback );
				updateQuickLinks();
	        },
			addController = function( data, callback ) {
				callback = callback || function() {};
				$ionicPopup = $ionicPopup || $injector.get( "$ionicPopup" );

				if ( !data.token && ( !data.ip || !data.password ) ) {
					$ionicPopup.alert( {
						template: "<p class='center'>Both an IP and password are required.</p>"
					} );
					callback( false );
					return;
				}

				if ( data.token && data.token.length !== 32 ) {
					$ionicPopup.alert( {
						template: "<p class='center'>A valid " + ( isOTCToken( data.token ) ? "OpenThings Cloud" : "Blynk" ) + " token is required. Please verify your token and try again.</p>"
					} );
					callback( false );
					return;
				}

				getControllerSettings( function( result ) {
					if ( !result ) {
						$ionicPopup.alert( {
							template: "<p class='center'>Unable to find device. Please verify the IP/password and try again.</p>"
						} );
						callback( false );
					}

                    $filter = $filter || $injector.get( "$filter" );

					if ( result.mac ) {
						result.ip = data.ip;
                        result.auth = data.token;
						result.password = data.password;

						if ( $filter( "filter" )( $rootScope.controllers, { "mac": result.mac } ).length > 0 ) {
							$ionicPopup.alert( {
								template: "<p class='center'>Device already added to site list.</p>"
							} );
							callback( false );
							return;
						}

						getControllerOptions(
                            function( reply ) {
                                angular.extend( result, reply );
                                $rootScope.controllers.push( result );
                                storage.set( { controllers: JSON.stringify( $rootScope.controllers ) } );
                                $rootScope.$broadcast( "controllersUpdated" );
                                callback( true );
                            },
                            data.token ? null : data.ip,
                            data.token ? data.token : null
                        );
					}

					if ( data.token && !isOTCToken( data.token ) ) {
                        result.auth = data.token;
                        result.bdmn = data.bdmn;
                        result.bprt = data.bprt;

                        $rootScope.controllers.push( result );
                        storage.set( { controllers: JSON.stringify( $rootScope.controllers ) } );
                        $rootScope.$broadcast( "controllersUpdated" );
                        callback( true );
					}
				},
				data.token ? null : data.ip,
				data.token ? data.token : null,
                data.bdmn && data.bprt ? data.bdmn + ":" + data.bprt : null
				);
			},
			checkNewController = function( callback, suppressLoader ) {
				$http = $http || $injector.get( "$http" );
				callback = callback || function() {};
				$ionicModal = $ionicModal || $injector.get( "$ionicModal" );

	            $http( {
	                method: "GET",
	                url: "http://192.168.4.1/js",
	                suppressLoader: typeof suppressLoader !== "undefined" ? suppressLoader : true,
					timeout: 5000,
					retryCount: 3
	            } ).then(
					function( result ) {
						if ( !result.data.ssids ) {
							callback( false );
							return;
						}

						var scope = $rootScope.$new();
						scope.data = {};
						scope.save = function() {
							scope.modal.hide();
							connectNewController( scope.data );
						};
						scope.ssids = result.data.ssids;
						$ionicModal.fromTemplateUrl( "templates/newControllerSetup.html", {
							scope: scope,
							animation: "slide-in-up"
						} ).then( function( modal ) {
							scope.modal = modal;
							modal.show();
						} );
						callback( true );
					},
					function() {
						callback( false );
					}
				);
			},
			connectNewController = function( data ) {
				$http = $http || $injector.get( "$http" );
				$ionicPopup = $ionicPopup || $injector.get( "$ionicPopup" );

	            $http( {
	                method: "GET",
	                url: "http://192.168.4.1/cc?ssid=" + encodeURIComponent( data.ssid ) + "&pass=" + encodeURIComponent( data.password )
	            } ).then(
					function( result ) {
						if ( result.data.result === 1 ) {
							setTimeout( saveNewController, 2000 );
						} else {
							$ionicPopup.alert( {
								template: "<p class='center'>Invalid SSID/password combination. Please try again.</p>"
							} ).then( checkNewController );
						}
					},
					function() {
						$ionicPopup.alert( {
							template: "<p class='center'>Unable to reach controller. Please try again.</p>"
						} ).then( checkNewController );
					}
				);
			},
			saveNewController = function() {
				$http = $http || $injector.get( "$http" );
				$ionicPopup = $ionicPopup || $injector.get( "$ionicPopup" );

	            $http( {
	                method: "GET",
	                url: "http://192.168.4.1/jt"
	            } ).then( function( result ) {
					if ( result.data.ip === 0 ) {
						$ionicPopup.alert( {
							template: "<p class='center'>Controller was unable to connect. Please check your SSID and password and try again.</p>"
						} );
						return;
					}

					$ionicPopup.alert( {
						template: "<p class='center'>Controller succesfully connected! Please wait while the device reboots.</p>"
					} );

					$rootScope.controllers.push( {
						ip: intToIP( result.data.ip ),
						password: "opendoor",
						name: "My OpenGarage"
					} );
					storage.set( { controllers: JSON.stringify( $rootScope.controllers ) } );
					$rootScope.$broadcast( "controllersUpdated" );
				} );
			},
			scanLocalNetwork = function( callback ) {
				if ( !window.networkinterface ) {
					callback( false );
					return;
				}

				window.networkinterface.getWiFiIPAddress(
                    function( address ) {
                        if ( !address ) {
                            callback( false );
                            return;
                        }

                        $q = $q || $injector.get( "$q" );
                        $http = $http || $injector.get( "$http" );
                        var router = address.ip.split( "." );
                        router.pop();

                        var baseip = router.join( "." ),
                            check = function( ip ) {
                                return $http( {
                                        method: "GET",
                                        url: "http://" + baseip + "." + ip + "/jc",
                                        suppressLoader: true,
                                        timeout: 6000,
                                        retryCount: 3
                                    } )
                                    .then( function( result ) {
                                        if ( result.data && result.data.mac ) {
                                            matches.push( baseip + "." + ip );
                                        }
                                    } )
                                    .catch( function() {
                                        return false;
                                    } );
                            },
                            queue = [], matches = [], i;

                        $rootScope.$broadcast( "loading:show" );

                        for ( i = 1; i <= 244; i++ ) {
                            queue.push( check( i ) );
                        }

                        $q.all( queue ).then( function() {
                            $rootScope.$broadcast( "loading:hide" );
                            callback( matches );
                        } );
                    },
                    function() {
                        callback( false );
                    }
                );
			},
			requestPassword = function( callback ) {
				$ionicPopup = $ionicPopup || $injector.get( "$ionicPopup" );

				$ionicPopup.prompt( {
					title: "Enter Controller Password",
					template: "Enter your controller's password below:",
					inputType: "password",
					inputPlaceholder: "Controller Password"
				} ).then( function( password ) {
					callback( password );
				} );
			},
			updateQuickLinks = function() {
				if ( !window.ThreeDeeTouch ) {
					return;
				}

				window.ThreeDeeTouch.isAvailable( function( isAvailable ) {
					if ( !isAvailable || !$rootScope.controllers.length ) {
						return;
					}

					var links = [],
						limit = $rootScope.controllers.length < 4 ? $rootScope.controllers.length : 4;

					for ( var i = 0; i < limit; i++ ) {
						links.push( {
							type: "toggle-" + $rootScope.controllers[ i ].mac,
							title: "Toggle " + $rootScope.controllers[ i ].name,
							iconType: "Update"
						} );
					}

					window.ThreeDeeTouch.configureQuickActions( links );
				} );
			},
			cancelPendingHttp = function() {
				$http = $http || $injector.get( "$http" );

				$http.pendingRequests.forEach( function( pendingReq ) {
					if ( pendingReq.cancel ) {
						pendingReq.cancel.resolve();
					}
				} );
			},
            /**
             * Returns the URL that requests to a controller should be relative to. If neither an IP address nor an
             * OpenThings Cloud token are specified, the active controller will be used.
             */
             getBaseUrl = function( ip, token ) {
                if ( ( token && isOTCToken( token ) ) || ( !ip && ( $rootScope.activeController && isOTCToken( $rootScope.activeController.auth ) ) ) ) {
                    return OPENTHINGS_CLOUD_HOST + "/forward/v1/" + ( token || $rootScope.activeController.auth );
                } else {
                    return "http://" + ( ip || $rootScope.activeController.ip );
                }
            },
            isOTCToken = function( token ) {
                return token && token.startsWith( "OT" );
            },
			$http, $q, $filter, $ionicPopup, $ionicModal;

	    if ( isFireFox ) {
			HTMLElement.prototype.click = function() {
				var evt = this.ownerDocument.createEvent( "MouseEvents" );
				evt.initMouseEvent( "click", true, true, this.ownerDocument.defaultView, 1, 0, 0, 0, 0, false, false, false, false, 0, null );
				this.dispatchEvent( evt );
			};
	    }

        $rootScope.$on( "controllersUpdated", updateQuickLinks );

	    // Return usable functions
	    return {
			isIE: isIE,
	        storage: storage,
	        getControllerSettings: getControllerSettings,
	        getControllerOptions: getControllerOptions,
	        updateController: updateController,
	        setController: setController,
	        getControllerIndex: getControllerIndex,
	        checkNewController: checkNewController,
	        updateQuickLinks: updateQuickLinks,
			showAddController: function( callback ) {
				callback = callback || function() {};
				$ionicPopup = $ionicPopup || $injector.get( "$ionicPopup" );

				var scope = $rootScope.$new();
				scope.data = {
					canScan: window.networkinterface ? true : false
				};
				scope.scan = function() {
					scanLocalNetwork( function( result ) {
						if ( !result || !result.length ) {
							$ionicPopup.alert( {
								template: "<p class='center'>No devices detected on your network</p>"
							} );
							return;
						}

						scope.data.foundControllers = result;
					} );
				};
				scope.save = function( ip ) {
					popup.close();
					requestPassword( function( password ) {
						addController( {
							ip: ip,
							password: password
						} );
					} );
				};

				var popup = $ionicPopup.show( {
					templateUrl: "templates/addController.html",
					title: "Add Controller",
					scope: scope,
					buttons: [
						{ text: "Cancel" },
						{
							text: "<b>Add</b>",
							type: "button-positive",
							onTap: function( e ) {
								if ( !scope.data.ip || !scope.data.password ) {
									e.preventDefault();
									return;
								}

								return true;
							}
						}
					]
				} );

				popup.then(
					function( isValid ) {
						if ( isValid ) {
							addController( scope.data, callback );
						}
					}
				);
			},
			showAddBlynk: function( callback ) {
				callback = callback || function() {};
				$ionicPopup = $ionicPopup || $injector.get( "$ionicPopup" );

				var scope = $rootScope.$new();
				scope.data = {};
				var popup = $ionicPopup.show( {
					templateUrl: "templates/addControllerBlynk.html",
					title: "Add Controller by Blynk",
					scope: scope,
					buttons: [
						{ text: "Cancel" },
						{
							text: "<b>OK</b>",
							type: "button-positive",
							onTap: function( e ) {
								if ( !scope.data.token ) {
									e.preventDefault();
									return;
								}

								return true;
							}
						}
					]
				} );

				popup.then(
					function( isValid ) {
						if ( isValid ) {
                            if ( !scope.data.bdmn ) {
                                scope.data.bdmn = "blynk-cloud.com";
                            }

                            if ( !scope.data.bprt ) {
                                scope.data.bprt = 80;
                            }

							addController( scope.data, callback );
						}
					}
				);
			},
			showAddOtc: function( callback ) {
				callback = callback || function() {};
				$ionicPopup = $ionicPopup || $injector.get( "$ionicPopup" );

				$ionicPopup.prompt( {
					title: "Add Controller by OpenThings Cloud",
					template: "Enter your OpenThings Cloud auth token below:",
					inputType: "text",
					inputPlaceholder: "Your OpenThings Auth token"
				} ).then( function( token ) {
					if ( token ) {
						addController( { token: token }, callback );
					}
				} );
			},
			toggleDoor: function( auth, callback, blynkServer ) {
				if ( typeof auth === "function" ) {
					callback = auth;
					auth = null;
				}

				callback = callback || function() {};
				$http = $http || $injector.get( "$http" );

				var promise;

				if ( ( auth && !isOTCToken( auth ) ) || ( $rootScope.activeController && $rootScope.activeController.auth && !isOTCToken( $rootScope.activeController.auth ) ) ) {
                    if ( !blynkServer ) {
                        if ( !$rootScope.activeController.bdmn ) {
                            blynkServer = "blynk-cloud.com";
                        } else {
                            blynkServer = $rootScope.activeController.bdmn;
                        }

                        if ( $rootScope.activeController.bprt ) {
                            blynkServer += ":" + $rootScope.activeController.bprt;
                        }
                    }

					promise = $http( {
						method: "POST",
						url: "https://opengarage.io/wp-admin/admin-ajax.php",
		                headers: { "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8" },
						data: "action=blynkCloud&server=" + encodeURIComponent( blynkServer ) + "&path=" + encodeURIComponent( ( auth || $rootScope.activeController.auth ) + "/update/V1?value=1" )
					} ).then( function() {
						setTimeout( function() {
							$http( {
								method: "POST",
								url: "https://opengarage.io/wp-admin/admin-ajax.php",
				                headers: { "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8" },
				                suppressLoader: true,
								data: "action=blynkCloud&server=" + encodeURIComponent( blynkServer ) + "&path=" + encodeURIComponent( ( auth || $rootScope.activeController.auth ) + "/update/V1?value=0" )
							} );
						}, $rootScope.activeController.cdt || 1000 );
					} );
				} else {
					promise = $http.get( getBaseUrl( null, auth ) + "/cc?dkey=" + encodeURIComponent( $rootScope.activeController.password ) + "&click=1" );
				}

	            promise.then(
					function() {
						callback( true );
					},
					function() {
						callback( false );
					}
				);
			},
			restartController: function() {
				$http = $http || $injector.get( "$http" );
				$ionicPopup = $ionicPopup || $injector.get( "$ionicPopup" );

				if ( $rootScope.activeController ) {
					$ionicPopup.confirm( {
						title: "Restart Controller?",
						template: "<p class='center'>Are you sure you want to restart the controller?</p>"
					} ).then( function( result ) {
						if ( result ) {
							$http.get( getBaseUrl() + "/cc?dkey=" + encodeURIComponent( $rootScope.activeController.password ) + "&reboot=1" );
						}
					} );
				}
			},
			saveOptions: function( settings, callback ) {
				$http = $http || $injector.get( "$http" );

	            return $http( {
	                method: "GET",
	                url: getBaseUrl() + "/co?dkey=" + $rootScope.activeController.password,
					params: settings,
					paramSerializer: "$httpParamSerializerJQLike"
	            } ).then(
					function( result ) {
						callback( result.data );
					},
					function() {
						callback( false );
					}
				);
			},
			changePassword: function() {
				$ionicPopup = $ionicPopup || $injector.get( "$ionicPopup" );

				var scope = $rootScope.$new();
				scope.pwd = {};

				$ionicPopup.show( {
					templateUrl: "templates/changePassword.html",
					title: "Change Password",
					scope: scope,
					buttons: [
						{ text: "Cancel" },
						{
							text: "<b>Go</b>",
							type: "button-positive"
						}
					]
				} ).then( function() {
					if ( !scope.pwd.nkey || !scope.pwd.ckey || scope.pwd.nkey !== scope.pwd.ckey  ) {
						return;
					}

                    $http = $http || $injector.get( "$http" );
                    $filter = $filter || $injector.get( "$filter" );

		            $http( {
		                method: "GET",
		                url: getBaseUrl() + "/co?dkey=" + $rootScope.activeController.password,
						params: scope.pwd,
						paramSerializer: "$httpParamSerializerJQLike"
		            } ).then( function() {
						$rootScope.activeController.password = scope.pwd.nkey;

						var index = $rootScope.controllers.indexOf( ( $filter( "filter" )( $rootScope.controllers, { "mac": $rootScope.activeController.mac } ) || [] )[ 0 ] );
						if ( index ) {
							$rootScope.controllers[ index ] = $rootScope.activeController;
							storage.set( { "controllers": JSON.stringify( $rootScope.controllers ), "activeController": JSON.stringify( $rootScope.activeController ) } );
							$rootScope.$broadcast( "controllersUpdated" );
						}

						$ionicPopup.alert( {
							template: "<p class='center'>Password updated succesfully!</p>"
						} );
					} );
				} );
			},
			getLogs: function( callback ) {
				if ( !$rootScope.activeController || ( !$rootScope.activeController.ip && !isOTCToken( $rootScope.activeController.auth ) ) ) {
					callback( false );
					return;
				}

				$http = $http || $injector.get( "$http" );

	            $http( {
	                method: "GET",
	                url: getBaseUrl() + "/jl"
	            } ).then(
					function( result ) {
						callback( result.data.logs.sort( function( a, b ) { return b[ 0 ] - a[ 0 ]; } ) );
					},
					function() {
						callback( false );
					}
				);
			},
			selectPhoto: function( $event, index, deletePhoto ) {
				$ionicModal = $ionicModal || $injector.get( "$ionicModal" );

				var scope = $rootScope.$new(),
					fileInput = angular.element( document.getElementById( "photoUpload" ) );

				scope.data = {
					image: false,
					cropped: false,
					index: false
				};

				scope.uploadPhoto = function( index ) {
					scope.crop.hide();

					if ( getControllerIndex() === index ) {
						$rootScope.activeController.image = scope.data.cropped;
						storage.set( { activeController: JSON.stringify( $rootScope.activeController ) } );
					}

					$rootScope.controllers[ index ].image = scope.data.cropped;
			        storage.set( { controllers: JSON.stringify( $rootScope.controllers ) } );
			        $rootScope.$broadcast( "controllersUpdated" );
				};

				$ionicModal.fromTemplateUrl( "templates/crop.html", {
					scope: scope
				} ).then( function( modal ) {
					scope.crop = modal;
				} );

				$event.stopPropagation();

				if ( deletePhoto ) {
					if ( getControllerIndex() === index ) {
						delete $rootScope.activeController.image;
						storage.set( { activeController: JSON.stringify( $rootScope.activeController ) } );
					}

					delete $rootScope.controllers[ index ].image;
			        storage.set( { controllers: JSON.stringify( $rootScope.controllers ) } );
			        $rootScope.$broadcast( "controllersUpdated" );
					return;
				}

				fileInput.parent()[ 0 ].reset();

				fileInput.one( "change", function() {
					var file = fileInput[ 0 ].files[ 0 ];

					if ( !file.type || !file.type.match( "image.*" ) ) {
						return;
					}

					var reader = new FileReader();
					reader.onload = function( evt ) {
						scope.data.image = evt.target.result;
						scope.data.index = index;
						scope.crop.show();
					};
					reader.readAsDataURL( file );
				} );

				fileInput[ 0 ].click();
			}
	    };
} ] );

/* global angular */

// OpenGarage
angular.module( "opengarage.watch", [] )
    .factory( "Watch", [ "$rootScope", function( $rootScope ) {

		var loadApp = function() {
            window.applewatch.loadAppMain( {
                title: "OpenGarage",
                label: {
                    value: "Toggle Garage Door",
                    color: "#FFA500",
                    font: {
                        size: 12
                    }
                },
                table: {
                    callback: "toggleDoorByIndex",
                    alpha: 1,
                    rows: makeAppList()
                }
            } );
        },
        makeAppList = function() {
            var rows = [];

            $rootScope.controllers.forEach( function( controller ) {
				var item = {
                    type: "OneColumnSelectableRowType",
                    group: {
                        backgroundColor: "#1884C4",
                        cornerRadius: 8
                    },
                    label: {
                        value: " " + controller.name
                    }
                };

                if ( controller.image ) {

					var canvas = document.createElement( "canvas" ),
						ctx = canvas.getContext( "2d" ),
						img = new Image();

					img.src = controller.image;

					canvas.width = 25;
					canvas.height = 30;
					ctx.drawImage( img, 0, 0, canvas.width, canvas.height );

					item.imageLeft = {
                        data: canvas.toDataURL( "image/png" ),
                        width: canvas.width,
                        height: canvas.height
                    };
                }

                rows.push( item );
            } );

            return rows;
        };

        $rootScope.$on( "controllersUpdated", function() {
            if ( window.applewatch ) {
                loadApp();
            }
        } );

        return {
            loadApp: loadApp
        };
    } ] );
